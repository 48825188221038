import { useConfig } from '@/hooks/useConfig';
import { cn } from '@/lib/cn';
import type { Preview } from '@/providers/windowkeeperProvider';
import { CubeIcon, XMarkIcon } from '@heroicons/react/16/solid';
import type {
  BaseInstance,
  IBaseInstanceConfig,
  ModelName,
} from '@pigello/pigello-matrix';
import { forwardRef } from 'react';
import { getTabIcon } from './tab-icon';

type WindowKeeperItemProps = {
  itemWidth: number;
  preview: Preview;
  onPreviewClick: (
    preview: Preview,
    config?: IBaseInstanceConfig<BaseInstance>
  ) => void;
  removePreview: (previewIdentifier: string) => void;
};

const WindowKeeperItem = forwardRef<HTMLButtonElement, WindowKeeperItemProps>(
  ({ itemWidth, preview, onPreviewClick, removePreview, ...props }, ref) => {
    const { config } = useConfig(preview.display.split(':')[0] as ModelName);

    return (
      <button
        {...props}
        ref={ref}
        style={{
          maxWidth: itemWidth,
          minWidth: itemWidth,
          width: itemWidth,
        }}
        className={cn(
          'group relative flex shrink-0 cursor-pointer items-center gap-2 whitespace-nowrap rounded-md border border-border bg-background px-2 py-1 transition-all hover:scale-[99%] hover:bg-muted',
          {
            'justify-center': itemWidth < 81,
            'bg-yellow-100': preview.type === 'nonsavedinstance',
          }
        )}
        onClick={() => onPreviewClick(preview, config)}
      >
        {getTabIcon(
          preview.display.split(':')[0].toLowerCase() as ModelName
        ) ?? <CubeIcon className='size-4 min-h-[16px] min-w-[16px]' />}
        {itemWidth > 80 && (
          <div className='flex flex-col justify-start truncate'>
            <span className='-mb-0.5 truncate text-start text-[10px] leading-tight'>
              {config?.verboseName}
            </span>
            <span className='truncate text-start text-xs leading-tight'>
              {preview.display.split(':')[1]}
            </span>
          </div>
        )}
        <div
          role='button'
          aria-label='Ta bort flik'
          tabIndex={0}
          onKeyDown={(e) => {
            e.preventDefault();
            if (e.key === 'Enter' || e.key === ' ') {
              removePreview(preview.identifier);
            }
          }}
          className='invisible absolute -right-2 -top-2 flex size-5 items-center justify-center rounded-full border border-border bg-background transition-all hover:bg-red-500 hover:text-red-200 group-hover:visible'
          onClick={() => removePreview(preview.identifier)}
        >
          <XMarkIcon className='size-3' />
        </div>
      </button>
    );
  }
);

WindowKeeperItem.displayName = 'WindowKeeperItem';

export default WindowKeeperItem;
