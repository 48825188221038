/**
 * Represents the various ready states of a WebSocket connection.
 */
export const ReadyState = {
  /**
   * The WebSocket connection has not been instantiated.
   */
  UNINSTANTIATED: -1,

  /**
   * The WebSocket connection is in the process of connecting.
   */
  CONNECTING: 0,

  /**
   * The WebSocket connection is open and ready to communicate.
   */
  OPEN: 1,

  /**
   * The WebSocket connection is in the process of closing.
   */
  CLOSING: 2,

  /**
   * The WebSocket connection is closed or couldn't be opened.
   */
  CLOSED: 3,
} as const;

export type ReadyStateType = (typeof ReadyState)[keyof typeof ReadyState];

/**
 * Handler type for the 'open' WebSocket event.
 * @param event - The event triggered when the WebSocket connection is opened.
 */
type OnOpen = (event: Event) => void;

/**
 * Handler type for the 'close' WebSocket event.
 * @param event - The event triggered when the WebSocket connection is closed.
 */
type OnClose = (event: CloseEvent) => void;

/**
 * Handler type for the 'error' WebSocket event.
 * @param event - The event triggered when an error occurs with the WebSocket connection.
 */
type OnError = (event: Event) => void;

/**
 * Handler type for the 'message' WebSocket event.
 * @param data - The data received from the WebSocket message.
 */
type OnMessage<T> = (data: T | null) => void;

/**
 * Interface representing an observer for WebSocket events.
 */
interface Observer<T = unknown> {
  /**
   * Callback for when the WebSocket connection is opened.
   */
  onOpen?: OnOpen;

  /**
   * Callback for when the WebSocket connection is closed.
   */
  onClose?: OnClose;

  /**
   * Callback for when an error occurs with the WebSocket connection.
   */
  onError?: OnError;

  /**
   * Callback for when a message is received from the WebSocket.
   */
  onMessage?: OnMessage<T>;
}

/**
 * Manages a WebSocket connection with support for observers and reconnection logic.
 */
export class SocketManager<T = unknown> {
  private url: string;
  private protocols: string | string[] | undefined;
  private socket: WebSocket | null = null;
  private observers: Set<Observer<T>> = new Set();
  private reconnectAttempts: number;
  private reconnectInterval: number;
  private retryOnError: boolean;
  private shouldReconnect: boolean;
  private attemptsMade: number = 0;
  private manuallyClosed: boolean = false;
  private hadError: boolean = false;
  private reconnectTimeoutId: number | null = null;

  /**
   * Creates an instance of SocketManager.
   * @param url - The WebSocket server URL.
   * @param options - Configuration options for reconnection and behavior.
   *   - `reconnectAttempts` - Number of reconnection attempts before giving up.
   *   - `reconnectInterval` - Delay between reconnection attempts in milliseconds.
   *   - `retryOnError` - Whether to retry connecting if an error occurs.
   *   - `shouldReconnect` - Whether the socket should attempt to reconnect on close.
   */
  constructor(
    url: string,
    options: {
      reconnectAttempts?: number;
      reconnectInterval?: number;
      retryOnError?: boolean;
      shouldReconnect?: boolean;
      protocols?: string | string[];
    } = {}
  ) {
    this.url = url;
    this.reconnectAttempts = options.reconnectAttempts ?? 3;
    this.reconnectInterval = options.reconnectInterval ?? 5000;
    this.retryOnError = options.retryOnError ?? true;
    this.shouldReconnect = options.shouldReconnect ?? true;
    this.protocols = options.protocols;
    this.connect();
  }

  /**
   * Adds an observer to listen for WebSocket events.
   * @param observer The observer containing event handlers.
   */
  public addObserver(observer: Observer<T>) {
    this.observers.add(observer);
    this.notifyObserver(observer);
  }

  /**
   * Removes an existing observer.
   * @param observer The observer to remove.
   */
  public removeObserver(observer: Observer<T>) {
    this.observers.delete(observer);
  }

  /**
   * Notifies a single observer based on the current WebSocket state.
   * @param observer - The observer to notify.
   */
  private notifyObserver(observer: Observer<T>) {
    if (!this.socket) return;

    const state = this.getReadyState();

    if (state === ReadyState.OPEN && observer.onOpen) {
      observer.onOpen(new Event('open'));
    }
  }

  /**
   * Notifies all observers of a specific WebSocket event.
   * @param eventType - The type of event to notify observers about.
   * @param data - Optional data associated with the event.
   */
  private notifyObservers(eventType: keyof Observer<T>, data?: unknown) {
    for (const observer of this.observers) {
      switch (eventType) {
        case 'onOpen':
          observer.onOpen?.(data as Event);
          break;
        case 'onClose':
          observer.onClose?.(data as CloseEvent);
          break;
        case 'onError':
          observer.onError?.(data as Event);
          break;
        case 'onMessage':
          observer.onMessage?.(data as T);
          break;
        default:
          break;
      }
    }
  }
  /**
   * Establishes the WebSocket connection and sets up event handlers.
   */
  private connect() {
    if (!this.url.startsWith('wss://')) {
      console.warn(
        'Insecure WebSocket connection detected. Consider using wss://'
      );
    }
    this.socket = new WebSocket(this.url, this.protocols);

    this.socket.onopen = (event) => {
      this.attemptsMade = 0;
      this.hadError = false;
      this.notifyObservers('onOpen', event);
    };

    this.socket.onmessage = (event) => {
      let parsedData: T | null;
      try {
        parsedData = JSON.parse(event.data);
      } catch (e) {
        parsedData = null;
      }
      this.notifyObservers('onMessage', parsedData);
    };

    this.socket.onerror = (event) => {
      this.hadError = true;
      this.notifyObservers('onError', event);
    };

    this.socket.onclose = (event) => {
      this.notifyObservers('onClose', event);
      const shouldAttemptReconnect =
        !this.manuallyClosed &&
        this.shouldReconnect &&
        (this.hadError ? this.retryOnError : true);

      if (shouldAttemptReconnect) {
        this.reconnect();
      }
    };
  }
  /**
   * Attempts to reconnect the WebSocket connection based on the configured options.
   */
  private reconnect() {
    if (this.attemptsMade < this.reconnectAttempts) {
      const delay = this.reconnectInterval * 2 ** this.attemptsMade;
      this.attemptsMade += 1;
      this.reconnectTimeoutId = window.setTimeout(() => {
        this.hadError = false;
        this.connect();
      }, delay);
    } else {
      this.notifyObservers(
        'onClose',
        new CloseEvent('close', {
          code: 1006,
          reason: 'Max reconnect attempts reached',
        })
      );
    }
  }

  /**
   * Closes the WebSocket connection and cleans up resources.
   */
  public close() {
    this.manuallyClosed = true;
    if (this.socket) {
      this.socket.close(1000, 'WebSocket closed by client');
      this.socket = null;
    }
    if (this.reconnectTimeoutId) {
      clearTimeout(this.reconnectTimeoutId);
      this.reconnectTimeoutId = null;
    }
    this.observers.clear();
  }

  /**
   * Retrieves the current ready state of the WebSocket connection.
   * @returns The current ready state as defined in `ReadyStateType`.
   */
  public getReadyState(): ReadyStateType {
    if (!this.socket) return ReadyState.UNINSTANTIATED;
    switch (this.socket.readyState) {
      case WebSocket.CONNECTING:
        return ReadyState.CONNECTING;
      case WebSocket.OPEN:
        return ReadyState.OPEN;
      case WebSocket.CLOSING:
        return ReadyState.CLOSING;
      case WebSocket.CLOSED:
        return ReadyState.CLOSED;
      default:
        return ReadyState.UNINSTANTIATED;
    }
  }

  /**
   * Retrieves the underlying WebSocket instance.
   * @returns The WebSocket instance or `null` if not instantiated.
   */
  public getSocketInstance() {
    return this.socket;
  }
}
