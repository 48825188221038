import type { ModalContent } from '@/providers/modal-provider';
import type { BaseInstance } from '@pigello/pigello-matrix';
import {
  CreateSingleInstanceModal,
  type CreateSingleInstanceModalProps,
} from './create-modal';

export const getCreateSingleInstanceModalContent = <T extends BaseInstance>({
  ...props
}: CreateSingleInstanceModalProps<T>): ModalContent => {
  return {
    body: <CreateSingleInstanceModal<T> {...props} />,
    externalModalHandling: true,
    isCloseOnEscape: false,
  };
};
