import { DynamicField } from '@/components/form/dynamic-field';
import { Icons } from '@/components/icons';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { useOpenDetailModal } from '@/components/ui/detailModalLink';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { useForm } from '@/hooks/useForm';
import { handleFormErrors } from '@/hooks/useForm/utils';
import { usePerms } from '@/hooks/usePerms';
import { useWindowKeeper } from '@/hooks/useWindowKeeper';
import { cn } from '@/lib/cn';
import { ModelNameUrlMap } from '@/lib/urls';
import { ObjectEntries } from '@/lib/utils';
import { createGenericInstance } from '@/requests/creating';
import { useCreateGenericInstance } from '@/requests/hooks';
import type { createGenericInstanceProps } from '@/requests/types';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MinusIcon,
  XMarkIcon,
} from '@heroicons/react/16/solid';
import {
  getDisplayValue,
  type BaseInstance,
  type IBaseInstanceConfig,
} from '@pigello/pigello-matrix';
import { useQueryClient } from '@tanstack/react-query';
import { usePathname } from 'next/navigation';
import type { MouseEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'sonner';
import { ChapterComp } from '../chapter';
import type { DefaultFormProps } from '../types';

export function DefaultCreateFormDashboard<
  Instance extends BaseInstance = BaseInstance,
>({
  title,
  chapters,
  cleanInstance,
  onSuccess,
  onError,
  config,
  horizontal,
  preMutatedValues,
  description,
  closeModal,
  persistedIdentifier,
}: DefaultFormProps<Instance> & {
  description?: string;
  closeModal: () => void;
}) {
  const { canCreate, fields } = usePerms<Instance>(config.modelName);
  const canNotHandleAllRequiredFields = ObjectEntries(fields).some(
    ([key, field]) => field.canHandle === false && config.fields[key]?.required
  );

  const [open, setOpen] = useState(false);
  const [identifier] = useState(
    persistedIdentifier ??
      `create_${config.modelName}_${window.crypto.randomUUID()}`
  );
  const [createMore, setCreateMore] = useState(false);
  const [activeTab, setActiveTab] = useState<string[]>([
    chapters?.[0]?.title ?? '',
  ]);

  const pathname = usePathname();

  const url = ModelNameUrlMap[config.modelName] ?? '/not-found';
  const navigate = useOpenDetailModal(url);
  const { addNonSavedInstance, removePreview } = useWindowKeeper();
  const containerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const createFn = async (options: createGenericInstanceProps<Instance>) => {
    return createGenericInstance<Instance>({
      ...options,
      modelName: config.modelName,
    });
  };

  const form = useForm<Instance>({
    defaultValues: cleanInstance(),
    isUpdate: false,
    config,
    preMutatedValues,
    persistedInstanceId: identifier,
    orderedFields: chapters?.map((chapter) => ({
      key: chapter.title,
      fields: chapter.fields,
    })),
  });

  const { isDirty, touchedFields } = form.formState;

  const { mutate, isPending } = useCreateGenericInstance<Instance>(
    createFn,
    {
      onSuccess: (data) => {
        if ('mtMotivation' in data) {
          queryClient.invalidateQueries({
            queryKey: [config?.monitoringConfig],
          });
          toast.warning('Kräver godkännande', {
            description: `${config.verboseName} ${getDisplayValue(
              config,
              data
            )} kräver godkännande innan resterande objekt kan skapas.`,
          });
        } else {
          toast.success('Skapandet lyckades');
        }
        onSuccess && onSuccess(data);
        form.reset();
        if (!createMore) {
          if (!('mtMotivation' in data) && pathname.startsWith('/dashboard')) {
            navigate(data.id);
          }
          closeModal();
        }
        scrollToSection(chapters?.[0]?.title ?? '');
      },
      onError: (error) => {
        if (error.status === 403) {
          toast.error('Du har inte behörighet att skapa detta objekt');
          return;
        }
        const field = handleFormErrors(error, form);
        toast.error(field);
        onError && onError();
      },
    },
    config.modelName
  );

  const onSubmit = useCallback(() => {
    const data = form.getDirtyData();
    mutate({
      body: { ...data },
    });
  }, [form, mutate]);

  const scrollToSection = (section: string) => {
    const element = document.getElementById(section);
    if (!element) {
      return;
    }
    const rect = element.getBoundingClientRect();
    const isFirstElement = section === chapters?.[0]?.title;
    containerRef.current?.scrollTo({
      top: isFirstElement ? 0 : rect.top + containerRef.current.scrollTop - 160,
      behavior: 'smooth',
    });
  };

  const closeAndReset = useCallback(() => {
    form.reset();
    removePreview(identifier);
    closeModal();
  }, [closeModal, form, identifier, removePreview]);

  const attemptClose = useCallback(
    (
      event:
        | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
        | KeyboardEvent
    ) => {
      event.preventDefault();
      if (
        !isDirty ||
        (isDirty && Object.keys(touchedFields ?? {}).length === 0)
      ) {
        return closeAndReset();
      }
      setOpen(true);
    },

    [closeAndReset, isDirty, touchedFields]
  );

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        attemptClose(e);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, [attemptClose, onSubmit]);

  return (
    <Dialog defaultOpen onOpenChange={closeModal}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className='relative m-auto h-[600px] w-[1100px] p-0 fill-mode-forwards sm:max-w-[1200px]'
      >
        <Form {...form}>
          <form
            className='grid h-[598px] grid-cols-5'
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <div className='col-span-5 flex items-center justify-between gap-2 border-b px-4 py-2'>
              <DialogTitle>{title}</DialogTitle>

              <div className='items-center space-x-2'>
                <Button
                  type='button'
                  variant='outline'
                  size='icon-sm'
                  className=' hover:bg-orange-300  hover:text-orange-800'
                  onClick={(e) => {
                    e.preventDefault();
                    addNonSavedInstance(
                      config as IBaseInstanceConfig<BaseInstance>,
                      identifier,
                      'Ej sparad',
                      {
                        chapterSuffix:
                          config.modelName === 'tenant'
                            ? chapters?.[0]?.title === 'Bolagsuppgifter'
                              ? '_company'
                              : '_private'
                            : undefined,
                      }
                    );
                    closeModal();
                  }}
                >
                  <MinusIcon className='size-4' />
                  <span className='sr-only'>Minimera</span>
                </Button>
                <Button
                  type='button'
                  variant='outline'
                  size='icon-sm'
                  className='hover:bg-red-500 hover:text-red-200'
                  onClick={attemptClose}
                >
                  <XMarkIcon className='size-4' />
                  <span className='sr-only'>Stäng</span>
                </Button>
              </div>
            </div>
            {chapters && (
              <div className='row-span-5 row-start-2 flex flex-col gap-2 rounded-l-md bg-transparent px-4 pt-8'>
                {chapters.map((chapter) => (
                  <button
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(chapter.title);
                    }}
                    className={cn(
                      'flex items-center gap-2 rounded-md px-2 py-1 transition-colors hover:bg-primary/5'
                    )}
                    key={chapter.description + chapter.title}
                  >
                    <div
                      className={cn(
                        'size-2 shrink-0 rounded-full bg-border',
                        activeTab.includes(chapter.title) && 'bg-primary'
                      )}
                    />
                    <p className='truncate text-sm'>{chapter.title}</p>
                  </button>
                ))}
              </div>
            )}

            <div
              ref={containerRef}
              className={cn(
                'col-span-4 row-span-4 row-start-2 h-full overflow-auto border-l px-4 pb-8 shadow-[-53px_0px_33px_-57px_rgba(0,0,0,0.05)]',
                {
                  'rounded-none border-none col-span-full': !chapters,
                }
              )}
            >
              {canNotHandleAllRequiredFields && (
                <Alert variant={'warning'} className='my-4 -mb-8'>
                  <ExclamationTriangleIcon className='size-4 text-yellow-600' />
                  <AlertTitle>OBS!</AlertTitle>
                  <AlertDescription>
                    Du har inte behörighet att hantera alla fält på detta
                    objekt. Detta kan innebära att du kan inte skapa objekt av
                    denna typ. Kontakta din administratör om du behöver ändra
                    detta.
                  </AlertDescription>
                </Alert>
              )}
              {description && (
                <Alert className='mt-6'>
                  <InformationCircleIcon className='size-4 text-primary' />
                  <AlertTitle>Info</AlertTitle>
                  <AlertDescription>{description}</AlertDescription>
                </Alert>
              )}
              {chapters ? (
                chapters.map((chapter, i) => (
                  <ChapterComp<Instance>
                    key={chapter.title}
                    activeTab={activeTab}
                    isFirst={i === 0}
                    hasDescription={!!description}
                    isLast={i === chapters.length - 1}
                    chapter={chapter}
                    containerRef={containerRef}
                    horizontal={horizontal}
                    isLoadingData={false}
                    setActiveTab={setActiveTab}
                    orderedFields={form.orderedFields}
                  />
                ))
              ) : (
                <div className='grid gap-3'>
                  <div
                    className={cn(
                      'mt-4 grid grid-cols-2 gap-8',
                      horizontal && 'grid-cols-1'
                    )}
                  >
                    {Object.values(form.fields ?? {}).map((field) => (
                      <DynamicField<Instance>
                        key={field.name.toString()}
                        formField={field}
                        horizontal={horizontal}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div
              className={cn(
                'col-span-4 col-start-2 row-start-6 flex items-center justify-end rounded-br-md border-l border-t bg-background px-4 py-2',
                {
                  'border-l-0 col-start-1 col-span-full': !chapters,
                }
              )}
            >
              <div className='flex items-center gap-2 '>
                <Switch
                  id='create-more'
                  checked={createMore}
                  onCheckedChange={setCreateMore}
                />
                <Label htmlFor='create-more'>Skapa fler</Label>

                {canCreate && (
                  <Button type='submit' disabled={!isDirty || isPending}>
                    Spara
                    {isPending && (
                      <Icons.loader className='ml-2 size-4 animate-spin' />
                    )}
                  </Button>
                )}
                <Button
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isDirty) return closeAndReset();
                    setOpen(true);
                  }}
                  variant={'outline'}
                >
                  Avbryt
                </Button>
              </div>
            </div>
          </form>
        </Form>
        <AlertDialog open={open} onOpenChange={setOpen}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Är du säker?</AlertDialogTitle>
              <AlertDialogDescription>
                Är du säker på att du vill avbryta? Du kommer förlora allt du
                skrivit.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={() => {
                  setOpen(false);
                }}
              >
                Avbryt
              </AlertDialogCancel>
              <Button
                onClick={() => {
                  setOpen(false);
                  closeAndReset();
                }}
              >
                Bekräfta
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DialogContent>
    </Dialog>
  );
}
