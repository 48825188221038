// use-web-socket.ts
import { BASE_PIGELLO_SOCKET_URL } from '@/requests/constants';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createObserver } from './observer-factory';
import {
  ReadyState,
  SocketManager,
  type ReadyStateType,
} from './socket-manager';
import { sockets } from './socket-map';
import type {
  ParsedEventData,
  UseWebSocketProps,
  UseWebSocketReturn,
} from './types';
import { useGetSocketToken } from './use-get-socket-token';
import { buildUrlWithParams } from './utils';

/**
 * Custom React hook to manage WebSocket connections with support for observers,
 * reconnection logic, and optional message filtering and transforming.
 *
 * @template T - The expected type of the raw WebSocket messages.
 * @template U - The expected type of the transformed WebSocket messages.
 * @param props - Configuration properties for the WebSocket connection.
 * @returns An object containing the WebSocket's ready state, the last transformed message,
 *          and utility functions to close the socket or retrieve the WebSocket instance.
 *
 * @example
 * ```tsx
 * interface RawMessage {
 *   type: string;
 *   payload: any;
 * }
 *
 * interface TransformedMessage {
 *   content: any;
 * }
 *
 * const { readyState, lastMessageParsed, closeSocket, getSocket } = useWebSocket<RawMessage, TransformedMessage>({
 *   url: 'wss://example.com/socket',
 *   onMessage: (data) => console.log(data),
 *   filter: (data) => data.type === 'UPDATE',
 *   transform: (data) => ({ content: data.payload }),
 * });
 * ```
 */
export const useWebSocket = <T = ParsedEventData, U = T>(
  props?: UseWebSocketProps<T, U>
): UseWebSocketReturn<U> => {
  const {
    url,
    token,
    protocols,
    queryParams,
    onOpen,
    onClose,
    onError,
    onMessage,
    enabled = true,
    reconnectAttempts = 3,
    reconnectInterval = 5000,
    retryOnError = true,
    shouldReconnect = true,
    share = true,
    filter,
  } = props ?? {};
  const [readyState, setReadyState] = useState<{
    [url: string]: ReadyStateType;
  }>({});
  const [lastMessageParsed, setLastMessageParsed] = useState<U | null>(null);
  const managerRef = useRef<SocketManager<T> | null>(null);
  const socketToken = useGetSocketToken();
  const handlersRef = useRef<{
    onOpen?: (event: Event) => void;
    onClose?: (event: CloseEvent) => void;
    onError?: (event: Event) => void;
    onMessage?: (data: U) => void;
    filter?: (data: T) => boolean;
    transform?: (data: T) => U;
  }>({});
  const fallBackUrl = BASE_PIGELLO_SOCKET_URL as string;
  const fullUrl = useMemo(
    () =>
      buildUrlWithParams(url ?? fallBackUrl, {
        ...queryParams,
        ...(token && !socketToken && { b: token }),
        ...(!token && socketToken && { b: socketToken }),
      }),
    [url, queryParams, token, socketToken, fallBackUrl]
  );

  useEffect(() => {
    handlersRef.current = { onOpen, onClose, onError, onMessage, filter };
  }, [onOpen, onClose, onError, onMessage, filter]);

  const initializeSocket = useCallback(() => {
    let manager: SocketManager<T>;
    if (share && sockets.has(fullUrl)) {
      manager = sockets.get(fullUrl)!;
    } else {
      manager = new SocketManager(fullUrl, {
        reconnectAttempts,
        reconnectInterval,
        retryOnError,
        shouldReconnect,
        protocols,
      });
      if (share) {
        sockets.set(fullUrl, manager as SocketManager<unknown>);
      }
    }

    managerRef.current = manager;

    setReadyState((prev) => ({ ...prev, [fullUrl]: manager.getReadyState() }));

    return manager;
  }, [
    fullUrl,
    protocols,
    reconnectAttempts,
    reconnectInterval,
    retryOnError,
    share,
    shouldReconnect,
  ]);

  useEffect(() => {
    if (!enabled || (!token && !socketToken) || (!url && !fallBackUrl)) {
      // If not enabled and there's an existing socket, close it
      if (share && sockets.has(fullUrl)) {
        const existingManager = sockets.get(fullUrl);
        existingManager?.close();
        sockets.delete(fullUrl);
      }
      return;
    }

    const manager = initializeSocket();

    const observer = createObserver(
      fullUrl,
      handlersRef.current,
      setReadyState,
      setLastMessageParsed
    );
    manager.addObserver(observer);
    return () => {
      manager.removeObserver(observer);

      if (
        !share &&
        managerRef.current &&
        managerRef.current.getReadyState() !== ReadyState.CONNECTING &&
        managerRef.current.getReadyState() !== ReadyState.CLOSING
      ) {
        managerRef.current.close();
        sockets.delete(fullUrl);
      }
    };
  }, [
    enabled,
    fallBackUrl,
    fullUrl,
    initializeSocket,
    share,
    socketToken,
    token,
    url,
  ]);

  const close = useCallback(() => {
    const doNotCloseState = [
      // ReadyState.CONNECTING,
      ReadyState.CLOSING,
      ReadyState.CLOSED,
    ] as number[];
    if (
      managerRef.current &&
      !doNotCloseState.includes(managerRef.current.getReadyState())
    ) {
      managerRef.current.close();
      if (share) {
        sockets.delete(fullUrl);
      }
    }
  }, [share, fullUrl]);

  const get = useCallback(() => {
    if (managerRef.current) {
      return managerRef.current.getSocketInstance();
    }
    return null;
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      close();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [close]);

  useEffect(() => {
    const handleOnline = () => {
      if ((token || socketToken) && (url || fallBackUrl) && enabled) {
        initializeSocket();
      }
    };

    const handleOffline = () => {
      close();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [token, initializeSocket, close, url, enabled, socketToken, fallBackUrl]);

  return {
    readyState: readyState[fullUrl] ?? ReadyState.UNINSTANTIATED,
    lastMessageParsed,
    closeSocket: close,
    getSocket: get,
  };
};
