// hooks/use-web-socket-4/observerFactory.ts
import { throttle } from 'lodash';
import type { Dispatch, SetStateAction } from 'react';
import { ReadyState, type ReadyStateType } from './socket-manager';

interface Handlers<T, U = T> {
  onOpen?: (event: Event) => void;
  onClose?: (event: CloseEvent) => void;
  onError?: (event: Event) => void;
  onMessage?: (data: U) => void;
  filter?: (data: T) => boolean;
  transform?: (data: T) => U;
}

export const createObserver = <T = unknown, U = T>(
  fullUrl: string,
  handlers: Handlers<T, U>,
  setReadyState: Dispatch<SetStateAction<{ [url: string]: ReadyStateType }>>,
  setLastMessageParsed: Dispatch<SetStateAction<U | null>>
) => ({
  onOpen: (event: Event) => {
    handlers.onOpen?.(event);
    setReadyState((prev) => ({ ...prev, [fullUrl]: ReadyState.OPEN }));
  },
  onClose: (event: CloseEvent) => {
    handlers.onClose?.(event);
    setReadyState((prev) => ({ ...prev, [fullUrl]: ReadyState.CLOSED }));
  },
  onError: (event: Event) => {
    handlers.onError?.(event);
    setReadyState((prev) => ({ ...prev, [fullUrl]: ReadyState.CLOSED }));
  },
  onMessage: (data: T | null) => {
    if (data === null) return;

    if (handlers.filter && !handlers.filter(data)) {
      return;
    }

    let transformedData: U;
    try {
      transformedData = handlers.transform
        ? handlers.transform(data)
        : (data as U);
    } catch (error) {
      console.warn('Error during data transformation:', error);
      return;
    }

    throttle(() => {
      setLastMessageParsed(transformedData);
      handlers.onMessage?.(transformedData);
    }, 100)();
  },
});
